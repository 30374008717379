import React from 'react'

function AuthProvider({ children }) {
  return (
    <>
      { children }
    </>
  )
}

export default AuthProvider
